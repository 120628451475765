import { Routes, Route, Navigate } from 'react-router-dom';
import applogo from './titus.png';
import './App.css';
import Navbar from './components/Navbar'
import Welcome from './components/Checkin/Welcome';
import Help from './components/Help';
import TherapistPreference from './components/Admin/TherapistPreference';
import PracticeLocations from './components/Admin/PracticeLocations';
import Reports from './components/Admin/Reports';
import { ToastContainer } from 'react-toastify';
import Users from './components/Admin/Users'
import Login from "./components/Login/Login"
import Plans from "./components/unAuth/Plans"
import CreateOrg from './components/unAuth/createorg'
import TokenData from './utils/tokendata'

//

function App() {

  return (
    <div className="App">
      {TokenData.isAuthenticated ? <Navbar /> : ""}
      <div className='content-centered' >
        <Routes>
          {TokenData.isAuthenticated ? <Route path="/" element={<Welcome />} /> : <Route path="/" element={<Login />} /> }
          {TokenData.isAdmin ? <Route path="/practicelocations" element={<PracticeLocations />} /> : <Route path="/practicelocations" element={<Navigate replace to="/" />} />}
          {TokenData.isAdmin ? <Route path="/therapistpreference" element={<TherapistPreference />} /> : <Route path="/therapistpreference" element={<Navigate replace to="/" />} />}
          {TokenData.isAdmin ? <Route path="/reports" element={<Reports />} /> : <Route path="/reports" element={<Navigate replace to="/" />} />}
          {TokenData.isAdmin ? <Route path="/users" element={<Users />} /> : <Route path="/users" element={<Navigate replace to="/" />} />}
          {/* {isAuthorized ? <Route path="/roles" element={<Roles />} /> : <Route path="/roles" element={<Navigate replace to="/" />} />} */}
          <Route path="/help" element={<Help />} />
          <Route path="/login" element={<Login />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/createorg" element={<CreateOrg />} />
        </Routes>
        <ToastContainer position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          className='customToastContainer font-15pt-left'
          draggable
        />
      </div>
      <footer className="App-footer bg-light">
        <img src={applogo} className="App-logo" alt="logo" />
      </footer>
    </div>
  );
}

export default App;