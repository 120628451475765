import React, { Component } from 'react';
import { Container, Form } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import '../Login/Login.css'
import BGImg from './bg.png'
// import ProImg from './plan-img/professional.png'
import BasicImg from './plan-img/starter.png'


class Plans extends Component {
    render() {
        return (
            <div className='content-login-bg' style={{ backgroundImage: `url(${BGImg})` }}>
                <Container className="p-5 mb-4 bg-light rounded-3 content-plan">
                    <div className='price-table-container'>
                        <section>
                            <Form action={process.env.REACT_APP_PAYMENT_SERVER_URL + "/create-checkout-session"} method="POST">
                                <Form.Control type="hidden" id="proPrice" name="priceId" value={process.env.REACT_APP_STRIPE_PLAN_ID} />
                                <img
                                    src={BasicImg}
                                    width="120"
                                    height="120"
                                    alt=''
                                />
                                <div className="name">Titus Pro</div>
                                <div className="price">$39.99 per month</div>
                                <div className="duration">o 1st location included</div>
                                <div className="duration">o $29.99/month for 2<sup>nd</sup> location</div>
                                <div className="duration">o $19.99 each location for 3<sup>rd</sup>  location onwards</div>
                                <div className="flex justify-center mt-12 location">
                                {/* <input type="button"
                                        className="increment-btn text-white increment-add bg-pasha py-2 px-4 rounded-lg" 
                                        value="-" disabled /> */}
                                    Locations: <input
                                        type="number"
                                        className="quantity-input w-8 appearance-none text-center"
                                        id="quantityinput_pro"
                                        name="quantityinput_pro"
                                        min="1"
                                        max="10"
                                        defaultValue="1"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                         />
                                    {/* <input type="button"
                                        className="increment-btn text-white increment-add bg-pasha py-2 px-4 rounded-lg" 
                                        value="+" /> */}
                                </div>
                                <input type='submit' id="pro-plan-btn" className='btn btn-primary plan-button' value='Select Plan' />
                            </Form>
                        </section>
                    </div>
                    <Form.Group className="margin-top-20">
                        <div className='signup-label'>
                            ALREADY HAVE AN ACCOUNT? <a href='/'>LOGIN</a>
                        </div>
                    </Form.Group>
                </Container>
            </div>
        )
    };
}

export default Plans;