
import jwt_decode from "jwt-decode";

// let isAuthorized = false
var TokenData = {
    isAuthenticated : false,
    isAdmin : false,
    isSystemAdmin : false,
    adminRole : "Checkin.Admin",
    OrganizationId : 0, 
    MaxLocations: 0,
    DefaultPracticeLocationId : 0,
    UserFullName: '',
    LoggedinUserId: 0,
    logoPath: '',
    orgName:'',
    orgPhone: '',
    orgEmail: '',
    slackToken: '',
    slackDeliveryChannelId: '',
}

 var token = sessionStorage.getItem('token');
  if (token) {
    
    TokenData.isAuthenticated = true

    var decodedToken = jwt_decode(token)
    if ((decodedToken.roles === TokenData.adminRole)) {
        TokenData.isAdmin = true
    }

    if (decodedToken.isSysAdmin === "True") {
        TokenData.isSystemAdmin = true
    }

    TokenData.OrganizationId = decodedToken.organizationId;
    TokenData.UserFullName = decodedToken.userFullName;
    TokenData.LoggedinUserId = decodedToken.id;
    TokenData.DefaultPracticeLocationId = sessionStorage.getItem('DefaultPracticeLocationId');
  }

  var orgToken = sessionStorage.getItem('orgToken');
  if(orgToken) {
    var decodedOrgToken = jwt_decode(orgToken)
    TokenData.MaxLocations = decodedOrgToken.maxLocations;
  }
  
  var orgInfo = JSON.parse(sessionStorage.getItem('organizationInfo'));

  if(orgInfo){
    // TODO add variables for Organization 
    TokenData.logoPath = orgInfo.logoPath;
    TokenData.orgName = orgInfo.name;
    TokenData.orgPhone = orgInfo.phone;
    TokenData.orgEmail = orgInfo.email;
    TokenData.slackToken = orgInfo.slackToken;
    TokenData.slackDeliveryChannelId = orgInfo.slackDeliveryChannelId;

  }

export default TokenData;
