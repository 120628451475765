import React, { Component } from 'react';
import Select from 'react-select';
import { Container, Form, FormGroup, FloatingLabel, Col, Row, Table } from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import { GetAuthToken } from '../GetAuthToken'
import { toast } from 'react-toastify';
import TokenData from '../../utils/tokendata'

let practiceLocations = [];
let optionsLocations = [];
let preferences = [];
let allTherapists = [];
let allTherapistPreferences = [];
let optionsTherapists = [];
let accountUsername = '';
let isSystemAdmin = false;
let userRoles = []
let adminRole = "Checkin.Admin"

const initialTime = '07:00'
const initialStartTime = '08:00'
const initialEndTime = '20:00'

const optionsDays = [
    { value: 'All', label: 'All Days' },
    { value: 'Working', label: 'All Working Days' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
]

class TherapistPreference extends Component {


    constructor(props) {
        super(props);

        this.state = {
            practiceLocations: [], selectedLocations: [], selectedDays: [], selectedTherapistName: '', optionsLocations: [],
            startTime: initialStartTime, endTime: initialEndTime, preferences: [], allTherapists: [], optionsTherapists: [],
            isDisabledAllDays: false
        }
        this.getPracticeLocations = this.getPracticeLocations.bind(this)
        this.getTherapists = this.getTherapists.bind(this)
        this.getTherapistPreferences = this.getTherapistPreferences.bind(this)
        this.handleLocationChange = this.handleLocationChange.bind(this)
        this.clearForm = this.clearForm.bind(this)
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this)
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this)
        this.renderPreference = this.renderPreference.bind(this)
        this.handleDeleteRow = this.handleDeleteRow.bind(this)
        this.formatTime = this.formatTime.bind(this);
        this.onClickAddNewTherapist = this.onClickAddNewTherapist.bind(this)
        this.handleAllDaysRadio = this.handleAllDaysRadio.bind(this)
        this.handleSpecificRadio = this.handleSpecificRadio.bind(this)
    }

    componentDidMount() {
        this.getPracticeLocations();
        this.getTherapistPreferences();
    }

    handleLocationChange = (selectedLocations) => {
        this.setState({ selectedLocations: selectedLocations });
    }

    handleDayChange = (selectedDays) => {
        if (selectedDays.find((d) => d.value === 'All')) {
            selectedDays = optionsDays.filter((od) => (od.value !== 'All' && od.value !== 'Working'))
        }
        if (selectedDays.find((d) => d.value === 'Working')) {
            selectedDays = optionsDays.filter((od) => (od.value !== 'All' && od.value !== 'Working' && od.value !== 'Saturday' && od.value !== 'Sunday'))
        }
        this.setState({ selectedDays: selectedDays });
    }

    clearForm = () => {
        var txtTherapistName = document.getElementById('floatingInputName');
        var chbxSlack = document.getElementById('formSlackCheckbox');
        var txtSlack = document.getElementById('floatingInputSlackId');
        var chbxSms = document.getElementById('formSmsCheckbox');
        var txtCellphone = document.getElementById('floatingInputCellphone');
        var chbxEmail = document.getElementById('formEmailCheckbox');
        var txtEmail = document.getElementById('floatingInputEmail');
        var radioAllDays = document.getElementById('24x7');
        var radioSpecific = document.getElementById('specific');

        txtTherapistName.value = ''
        chbxSlack.checked = false
        txtSlack.value = ''
        chbxSms.checked = false
        txtCellphone.value = ''
        chbxEmail.checked = false
        txtEmail.value = ''
        radioAllDays.checked = false
        radioSpecific.checked = false

        preferences = []
        this.setState({ selectedLocations: [], selectedDays: [], startTime: initialTime, endTime: initialTime })
    }

    handleTherapistChange = (selectedTherapistName) => {
        var therapist = allTherapists.find((th) => th.id === selectedTherapistName.value);
        var therapistPreferences = allTherapistPreferences.filter((th) => th.therapistid === selectedTherapistName.value);
        var txtTherapistName = document.getElementById('floatingInputName');
        var chbxSlack = document.getElementById('formSlackCheckbox');
        var txtSlack = document.getElementById('floatingInputSlackId');
        var chbxSms = document.getElementById('formSmsCheckbox');
        var txtCellphone = document.getElementById('floatingInputCellphone');
        var chbxEmail = document.getElementById('formEmailCheckbox')
        var txtEmail = document.getElementById('floatingInputEmail')

        if (selectedTherapistName.value === -1) {
            this.clearForm()
        }
        else {
            txtTherapistName.value = therapist.name
            chbxSlack.checked = therapist.isslack
            txtSlack.value = therapist.slackmemberid
            chbxSms.checked = therapist.issms
            txtCellphone.value = therapist.cellphonenumber
            chbxEmail.checked = therapist.isemail
            txtEmail.value = therapist.email
            preferences = therapistPreferences.map((tp) =>
            ({
                id: tp.preferenceid,
                location: tp.locationname,
                day: tp.dayofweek,
                startTime: tp.starttime,
                endTime: tp.endtime,
                isDeleted: 0
            }));
        }
        this.setState({ selectedTherapistName: selectedTherapistName, preferences: preferences });
    }

    onClickAddNewTherapist = (e) => {
        this.handleTherapistChange({ value: -1, label: '' });
    }

    onClickAdd = (e) => {
        e.preventDefault();

        var allDaysRadio = document.getElementById('24x7');
        var specificRadio = document.getElementById('specific');
        if (this.state.selectedLocations.length === 0) {
            toast.error('A location must be selected', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (allDaysRadio.checked === false && specificRadio.checked === false) {
            toast.error('A type of working hours for the therapist must be selected', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (allDaysRadio.checked) {
            this.state.selectedLocations.map((loc) => optionsDays.filter((od) => (od.value !== 'All' && od.value !== 'Working')).map((day) => {
                (preferences.push({
                    id: -1,
                    location: loc.value,
                    day: day.value,
                    startTime: '00:00',
                    endTime: '23:59',
                    isDeleted: 0
                }))
            }));
        }
        else {
            this.state.selectedLocations.map((loc) => this.state.selectedDays.map((day) => {
                (preferences.push({
                    id: -1,
                    location: loc.value,
                    day: day.value,
                    startTime: this.state.startTime,
                    endTime: this.state.endTime,
                    isDeleted: 0
                }))
            }));
        }
        this.setState({ preferences: preferences });
    }

    renderPreference(preference, index) {
        if (preference.isDeleted === 0)
            return (
                <tr key={index}>
                    <td>{preference.location}</td>
                    <td>{preference.day}</td>
                    <td>{this.formatTime(preference.startTime)}</td>
                    <td>{this.formatTime(preference.endTime)}</td>
                    <td>
                        <i className="bi bi-trash text-failure" width="32" height="32" aria-hidden="true"
                            onClick={e => {
                                e.persist();
                                this.handleDeleteRow(preference, index);
                            }}
                        ></i>
                    </td>
                </tr>
            )
    }

    formatTime(str) {
        var d = new Date("01/01/1900 " + str);
        return d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0");
    }

    handleDeleteRow(preference, index) {
        preferences[index].isDeleted = 1;
        this.setState({ preferences: preferences });
    }

    handleStartTimeChange(startTime) {
        var hours = Math.floor(startTime / 3600);
        var minutes = Math.floor(startTime % 3600 / 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        var selectedStartTime = (hours + ':' + minutes);

        this.setState({ startTime: selectedStartTime });
    }

    handleEndTimeChange(endTime) {
        var hours = Math.floor(endTime / 3600);
        var minutes = Math.floor(endTime % 3600 / 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        var selectedEndTime = (hours + ':' + minutes);

        this.setState({ endTime: selectedEndTime });
    }

    handleAllDaysRadio() {
        this.setState({ isDisabledAllDays: true })
    }

    handleSpecificRadio() {
        this.setState({ isDisabledAllDays: false })
    }

    getPracticeLocations = async () => {
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetLocations?organizationid=${TokenData.OrganizationId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    practiceLocations = result;
                    optionsLocations = practiceLocations.map((loc) => ({ value: loc.name, label: loc.name }));
                    this.setState({ practiceLocations: result, optionsLocations: optionsLocations });
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    getTherapists = async () => {
        var url = `${process.env.REACT_APP_FUNCTIONS_URL}/GetAllTherapists?organizationid=${TokenData.OrganizationId}`
        if (!(isSystemAdmin || userRoles.includes(adminRole))) {
            url = url + '&email=' + accountUsername
        }
        await fetch(url, {

            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    allTherapists = result;
                    optionsTherapists = allTherapists.map(t => ({ value: t.id, label: t.name }))
                    if (isSystemAdmin || userRoles.includes(adminRole)) {
                        this.setState({ allTherapists: allTherapists, optionsTherapists: optionsTherapists });
                    } else {
                        this.setState({ allTherapists: allTherapists, optionsTherapists: optionsTherapists, selectedTherapistName: optionsTherapists[0] })
                        this.handleTherapistChange(optionsTherapists[0])
                    }
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    getTherapistPreferences = async () => {
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetAllTherapistPreferences?organizationid=${TokenData.OrganizationId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    allTherapistPreferences = result;
                    this.getTherapists()
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    handleSubmit = (e) => {
        e.preventDefault();

        var txtTherapistName = document.getElementById('floatingInputName');
        var chbxSlack = document.getElementById('formSlackCheckbox');
        var txtSlack = document.getElementById('floatingInputSlackId');
        var chbxSms = document.getElementById('formSmsCheckbox');
        var txtCellphone = document.getElementById('floatingInputCellphone');
        var chbxEmail = document.getElementById('formEmailCheckbox')
        var txtEmail = document.getElementById('floatingInputEmail')

        var therapist = this.state.allTherapists.find((th) => th.id === this.state.selectedTherapistName.value)
        var therapistPreferences = this.state.preferences.map((p) => ({
            therapistPreferenceId: p.id,
            locationName: p.location,
            dayOfWeek: p.day,
            startTime: p.startTime,
            endTime: p.endTime,
            isDeleted: p.isDeleted
        }))

        var isSlack = 0;
        var isSms = 0;
        var isEmail = 0;
        if (chbxSlack.checked === true) {
            isSlack = 1;
        }

        if (chbxSms.checked === true) {
            isSms = 1;
        }

        if (chbxEmail.checked === true) {
            isEmail = 1;
        }

        if (typeof therapist !== 'object') {
            therapist = { id: -1 }
        }

        this.setTherapistAndPreference(therapist.id, TokenData.OrganizationId, txtTherapistName.value, txtCellphone.value, txtSlack.value,
            txtEmail.value, isSms, isSlack, isEmail, therapistPreferences);

    }

    setTherapistAndPreference = async (therapistId, organizationId, therapistName,
        cellphoneNumber, slackMemberId, email, isSms, isSlack, isEmail,
        therapistPreferences) => {
        toast.promise(
            fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/SetTherapistPreferences`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                },
                body: JSON.stringify(
                    {
                        therapistId: therapistId,
                        organizationId: organizationId,
                        therapistName: therapistName,
                        cellphoneNumber: cellphoneNumber,
                        slackMemberId: slackMemberId,
                        email: email,
                        isSms: isSms,
                        isSlack: isSlack,
                        isEmail: isEmail,
                        therapistPreferences: therapistPreferences
                    })
            },
            ).then(res => res.json())
                .then(
                    (result) => {
                        this.clearForm()
                        this.setState({ selectedTherapistName: '', preferences: preferences })
                        this.getTherapists()
                        this.getTherapistPreferences()
                    },
                    (error) => {
                        console.log('error', error)
                    }),
            {
                pending: 'Saving...',
                success: "Therapist preferences are saved successfully!",
                error: "Failed to save, please try again."
            });
    }

    render() {
        return (
            <Container className="content-xlarge p-3">
                <GetAuthToken>
                    {({ userName, isSysAdmin, roles }) => { isSystemAdmin = isSysAdmin; accountUsername = userName; userRoles = roles; return <div></div> }}
                </GetAuthToken>
                <Form onSubmit={this.handleSubmit}>
                    <Container className="p-5 mb-4 bg-light rounded-3 content">
                        <Form.Group className="align-right remove-margin-40" >
                            <input type="button" onClick={this.onClickAddNewTherapist} value="Add New Therapist" className='btn btn-primary' />
                            <input type="submit" value="Save" className='btn btn-primary' />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Select
                                name="select-therapist"
                                value={this.state.selectedTherapistName}
                                onChange={this.handleTherapistChange}
                                options={this.state.optionsTherapists}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <FloatingLabel
                                controlId="floatingInputName"
                                label="Name:"
                                className="">
                                <Form.Control type="text" placeholder="Enter name" />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Form.Label>Notification Preference: </Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" id="formSmsCheckbox" label="Text Message" />
                            <FloatingLabel
                                controlId="floatingInputCellphone"
                                label="Cellphone Number:">
                                <Form.Control type="text" placeholder="Enter Cellphone Number" />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Form.Check type="checkbox" id="formSlackCheckbox" label="Slack" />
                            <FloatingLabel
                                controlId="floatingInputSlackId"
                                label="Member Slack ID:">
                                <Form.Control type="text" placeholder="Enter Member Slack ID" />
                            </FloatingLabel>
                            <div className='div-full-right'>
                                <a href="https://www.workast.com/help/articles/61000165203/" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-info-circle text-success" width="32" height="32" fill="currentColor" title="Click here to edit the foo" />
                                </a>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Form.Check type="checkbox" id="formEmailCheckbox" label="Email" />
                            <FloatingLabel
                                controlId="floatingInputEmail"
                                label="E-mail:">
                                <Form.Control type="text" placeholder="Enter Email" />
                            </FloatingLabel>
                        </Form.Group>

                    </Container>
                    <Container className="p-4 mb-4 bg-light rounded-3">
                        <Form.Group className="mb-3" controlId="selectLocationDayTime">
                            <Row>
                                <Col md={5}>
                                    <Form.Label>Location(s):</Form.Label>
                                    <Select
                                        name="select-location"
                                        value={this.state.selectedLocations}
                                        onChange={this.handleLocationChange}
                                        isMulti
                                        options={optionsLocations}
                                    >
                                    </Select>
                                </Col>
                                <Col md={5}>
                                    <fieldset id='titus-fieldset'>
                                        <legend>Please select therapists working hours</legend>
                                        <p>
                                            <input
                                                type="radio"
                                                name="workhours"
                                                value="24x7"
                                                id="24x7"
                                                className='margin-1-rem'
                                                onClick={this.handleAllDaysRadio}
                                            />
                                            <label htmlFor="24x7">24x7</label>
                                        </p>
                                        <p>
                                            <input
                                                type="radio"
                                                name="workhours"
                                                value="specific"
                                                id="specific"
                                                className='margin-1-rem'
                                                onClick={this.handleSpecificRadio}
                                            />
                                            <label htmlFor="specific">Specific Day/Hours</label>
                                        </p>
                                    </fieldset>
                                </Col>
                                <Col md={2}>
                                    <input onClick={this.onClickAdd} type="button" value="Add" className='btn btn-primary' />
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col md={5}>
                                    <Form.Label>Day(s):</Form.Label>
                                    <Select
                                        name="select-day-of-week"
                                        value={this.state.selectedDays}
                                        onChange={this.handleDayChange}
                                        isMulti
                                        options={optionsDays}
                                        id="select-day-of-week"
                                        isDisabled={this.state.isDisabledAllDays}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Form.Label>Start Time:</Form.Label>
                                    <TimePicker onChange={this.handleStartTimeChange} format={24} value={this.state.startTime} initialValue={this.initialStartTime} start={initialTime} end="23:45" step={15} disabled={this.state.isDisabledAllDays} />
                                </Col>
                                <Col md={2}>
                                    <Form.Label>End Time:</Form.Label>
                                    <TimePicker onChange={this.handleEndTimeChange} format={24} value={this.state.endTime} initialValue={this.initialEndTime} start={initialTime} end="23:45" step={15} disabled={this.state.isDisabledAllDays} />
                                </Col>

                            </Row>
                        </Form.Group>
                        <FormGroup className="mb-3" controlId="tablePreferences">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Location(s)</th>
                                        <th>Day(s)</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.preferences.map(this.renderPreference)}
                                </tbody>
                            </Table>
                        </FormGroup>
                    </Container>
                    <div className='mb-5'>&nbsp;</div>
                </Form>
            </Container >
        );
    }
}

export default TherapistPreference;