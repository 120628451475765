import React, { Component } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TokenData from '../utils/tokendata';

class Help extends Component {
    render() {
        setTimeout(() => {
            window.location.replace('/');
        }, 30000);

        return (
            <Container className="content-medium p-3" >
                <Container className="p-5 mb-4 bg-light rounded-3">
                    <Form.Label className='font-15pt-left'>
                        Thank you for visiting {TokenData.orgName}.<br /><br />
                        If you are a new client in need of assistance, please call us at {TokenData.orgPhone} or send an email to {TokenData.orgEmail}. If we are unable to answer your call, please leave a message and one of our support staff members will follow up with you shortly.
                    </Form.Label>
                    <br /><br />
                    <Form.Label className='font-15pt-left' >
                        For deliveries requiring a signature, please call {TokenData.orgPhone}.
                    </Form.Label>
                    <br /><br />
                    <div className='align-right'>
                        <Link to="/" className="btn btn-primary">Back</Link>
                    </div>
                </Container>
            </Container>
        );
    }
}
export default Help;