import React, { Component } from 'react';
import Modal from '../Modal';
import { Container, Form, FloatingLabel, Table } from 'react-bootstrap';
import Select from 'react-select';
import { GetAuthToken } from '../GetAuthToken';
import bcrypt from 'bcryptjs';
import { toast } from 'react-toastify';
import TokenData from '../../utils/tokendata'

let userId = -1;

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToken: '', usersList: [], modalUser: false, modalRoles: false, messageCreate: '',
            selectedRoles: [], selectedUser: '', optionsLocations: [],
            optionsRoles: [{ value: 1, label: "Checkin.Admin" }, { value: 2, label: "Checkin.User" }],
        }
        this.renderUsers = this.renderUsers.bind(this)
        this.getRoles = this.getRoles.bind(this)
        this.getPracticeLocations = this.getPracticeLocations.bind(this)
        this.getUsersList = this.getUsersList.bind(this)
        this.handleUserUpdate = this.handleUserUpdate.bind(this)


        this.getRoles();
        this.getPracticeLocations();
        this.getUsersList();
    }

    getUsersList = async () => {
        await fetch(`${process.env.REACT_APP_WEB_API_URL}/user/all?organizationid=${TokenData.OrganizationId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    this.setState({ usersList: result.result })
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    getRoles = async () => {
        await fetch(`${process.env.REACT_APP_WEB_API_URL}/role/all?organizationid=1`, //hard coding 1 org id because the same roles will be use by every org
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                },
            },
        ).then(res => res.json())
            .then(
                (result) => {
                    this.setState({ optionsRoles: result.result.map((role) => ({ value: role.id, label: role.name })) });
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    getPracticeLocations = async () => {
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetLocations?organizationid=${TokenData.OrganizationId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    this.setState({ optionsLocations: result.map((loc) => ({ value: loc.id, label: loc.name })) });
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    generatePassword(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    handleChange(e) {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    }

    handleSaveButton = () => {
        // console.log(this.state.usersList)
        // console.log(this.state.usersList.filter(u => u.isDirty === true))
        let updatedRecords = this.state.usersList.filter(u => u.isDirty === true);
        updatedRecords.forEach(user => {
            this.handleUserUpdate(user.id, user.roleId, user.isActive, user.defaultLocationId);
        });

       //window.location.replace('/users');

    }

    handleUserUpdate = async (userId, roleId, isActive, locationId) => {
        const userRoleViewModel = {
            UserId: userId,
            RoleId: roleId,
            IsActiveUser: isActive,
            DefaultLocationId: locationId,
            UpdatedByUserId: TokenData.LoggedinUserId,
            OrganizationId: TokenData.OrganizationId
        }
        console.log('userroleviewmodel', userRoleViewModel)

        toast.promise(
            fetch(`${process.env.REACT_APP_WEB_API_URL}/user/updateuser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userRoleViewModel)
            },
            ).then(res => res.json())
                .then(
                    (result) => {
                        this.setState({ messageCreate: result.message })
                        this.modalUserClose();
                        
                    },
                    (error) => {
                        console.log('error', error)
                    }
                ),
            {
                pending: 'Saving...',
                success: "User updated successfully!",
                error: "Failed to assign roles, please try again."
            });
    }

    handleModalUserSubmit = async (e) => {
        var isActive = document.getElementById('formIsActiveCheckbox');
        var txtFirstName = document.getElementById('floatingInputFirstName');
        var txtMiddleName = document.getElementById('floatingInputMiddleName');
        var txtLastName = document.getElementById('floatingInputLastName');
        var txtEmail = document.getElementById('floatingInputEmail');
        var txtPassword = document.getElementById('inputPassword');

        const userViewModel = {
            Username: txtEmail.value,
            IsActive: isActive.checked,
            FirstName: txtFirstName.value,
            MiddleName: txtMiddleName.value,
            LastName: txtLastName.value,
            Password: bcrypt.hashSync(txtPassword.value),
            OrganizationId: TokenData.OrganizationId,
            CreatedByUserId: userId
        };
        console.log('userviewmodel password', userViewModel.Password)

        toast.promise(
            fetch(`${process.env.REACT_APP_WEB_API_URL}/user/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userViewModel)
            },
            ).then(res => res.json())
                .then(
                    (result) => {
                        console.log('resultado', result)
                        if (result.errors) {
                            throw new Error()
                        }
                        this.modalUserClose();
                        this.setState({ messageCreate: result.message })
                        window.location.replace('/users');
                    },
                    (error) => {
                        console.log('error', error)
                    }
                )
            ,
            {
                pending: 'Saving...',
                success: "User is saved successfully!",
                error: "Failed to save, please try again."
            });

    }

    modalUserOpen() {
        this.setState({ modalUser: true });
    }

    modalUserClose() {
        this.setState({ modalUser: false });
    }

    handleRoleChange = (userId, e) => {
        var users = this.state.usersList;
        var index = users.findIndex(u => u.id === userId);
        users[index]["roleName"] = e.label;
        users[index]["roleId"] = e.value;
        users[index]["isDirty"] = true;
        this.setState({ usersList: users });
        // console.log(this.state.usersList);
    }

    handleLocationChange = (userId, e) => {
        var users = this.state.usersList;
        var index = users.findIndex(u => u.id === userId);
        users[index]["defaultLocation"] = e.label;
        users[index]["defaultLocationId"] = e.value;
        users[index]["isDirty"] = true;
        this.setState({ usersList: users });
    }

    handleActivateChange = (userId, e) => {

        var checkBox = document.getElementById('active' + userId);

        if (checkBox.checked === false) { // Only ask in case of deactivation.
            let ans = window.confirm("are you sure you want to deactivate the user?");
            if (!ans) {
                checkBox.checked = true; // if user decide not to deactivate, keep the checkbox on.
                return true;
            }
        }
        var users = this.state.usersList;
        var index = users.findIndex(u => u.id === userId);
        users[index]["isActive"] = checkBox.checked;
        users[index]["isDirty"] = true;
        this.setState({ usersList: users });
        return true;
    }

    renderUsers(user, index) {
        return (

            <tr key={index}>
                <td>{user.fullName}</td>
                <td>{user.username}</td>
                <td>
                    <Select
                        name={'select-location-' + user.id}
                        id={'select-location-' + user.id}
                        options={this.state.optionsLocations}
                        defaultValue={this.state.optionsLocations.filter(o => o.label === user.defaultLocation)}
                        onChange={this.handleLocationChange.bind(this, user.id)}
                        placeholder="No default location">
                    </Select>
                </td>
                <td>
                    <Select
                        name={'select-role-name-' + user.id}
                        id={'select-role-id-' + user.id}
                        inputId={'select-role-input-' + user.id}
                        options={this.state.optionsRoles}
                        defaultValue={this.state.optionsRoles.filter(o => o.label === user.roleName)}
                        onChange={this.handleRoleChange.bind(this, user.id)}
                        placeholder="No role">
                    </Select>
                </td>
                <td className='action-column'>
                    <input type="checkbox" id={'active' + user.id}
                        defaultChecked={user.isActive} onChange={this.handleActivateChange.bind(this, user.id)}></input>
                </td>
                {/* <td className='action-column'>
                    <i className="bi bi-save text-failure action-column-icon" width="32" height="32" aria-hidden="true"
                        onClick={e => {
                            e.persist();
                            console.log(document.querySelector("#select-role-"+user.id).data)
                            //this.handleUserUpdate(user.id, 1, true, 14);
                        }}
                    ></i>
                    <i className="bi bi-archive text-failure action-column-icon" width="32" height="32" aria-hidden="true"
                        onClick={e => {
                            e.persist();
                            let ans = window.confirm("are you sure you want to deactivate the user?"); 
                            if(ans) 
                            {
                                //this.handleUserUpdate(user.id, 1, false, 14);
                            }
                        }}
                    ></i>
                </td> */}
            </tr>
        )
    }

    render() {
        return (
            <Container className="p-4 mb-4 bg-light rounded-3">
                <div>
                    <div className='align-right'>
                        <input type="button" value="Add New User" className='btn btn-primary' onClick={e => this.modalUserOpen(e)} />
                        <input type="button" value="Save" className='btn btn-primary' onClick={this.handleSaveButton.bind(this)} />
                    </div>
                    <Modal show={this.state.modalUser} handleClose={e => this.modalUserClose(e)}>
                        <Container className="content-xlarge p-3">
                            <Container className="p-5 mb-4 bg-light rounded-3 content">
                                <h2>Create an User</h2>
                                <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" id="formIsActiveCheckbox" label="Is Active?" defaultChecked />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingInputFirstName"
                                        label="First Name:">
                                        <Form.Control type="text" placeholder='First Name' />
                                    </FloatingLabel>
                                    <br />
                                    <FloatingLabel
                                        controlId="floatingInputMiddleName"
                                        label="Middle Name:">
                                        <Form.Control type="text" placeholder='Middle Name' />
                                    </FloatingLabel>
                                    <br />
                                    <FloatingLabel
                                        controlId="floatingInputLastName"
                                        label="Last Name:">
                                        <Form.Control type="text" placeholder='Last Name' />
                                    </FloatingLabel>
                                    <br />
                                    <FloatingLabel
                                        controlId="floatingInputEmail"
                                        label="E-mail:">
                                        <Form.Control type="email" placeholder="name@example.com" />
                                    </FloatingLabel>
                                    <br />
                                    <FloatingLabel
                                        label="Password:">
                                        <Form.Control type="password" placeholder="Password" id="inputPassword" />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="align-right remove-margin-10" >
                                    <input type="button" value="Save" onClick={e => this.handleModalUserSubmit(e)} className='btn btn-primary' />
                                </Form.Group>
                            </Container>
                        </Container>
                    </Modal>

                    <GetAuthToken>
                        {({ id }) => { userId = id; return <div></div> }}
                    </GetAuthToken>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Username</th>
                                <th style={{ width: '250px' }}>Default Location</th>
                                <th style={{ width: '250px' }}>Role</th>
                                <th className='action-column'>Active</th>
                                {/* <th className='action-column'></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.usersList.map(this.renderUsers)}
                        </tbody>
                    </Table>
                </div>
            </Container>
        );
    }
}


export default Users;