import React from "react";
import { Button } from "react-bootstrap";
import TokenData from '../utils/tokendata'

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */


const Navbar = () => {



  const handleLogout = () => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('DefaultPracticeLocationId')
    sessionStorage.removeItem('orgToken')
    sessionStorage.removeItem('organizationInfo')

    window.location = '/';
  };

  return (
    <>
      <nav className="navbar bg-light">
        <div><img src={TokenData.logoPath} className="Org-logo" alt="logo" /></div>
        <ul className="links">
          <a href="/">Welcome</a>
          {TokenData.isAdmin ? <a href="/practicelocations">Locations</a> : ""}
          {TokenData.isAdmin ? <a href="/therapistpreference">Therapist Preference</a> : ""}
          {TokenData.isAdmin ? <a href="/reports">Reports</a> : ""}
          {TokenData.isAdmin ? <a href="/users">Users</a> : ""}
          {/* {isAuthorizedAdmin ? <a href="/roles">Roles</a> : ""} */}
          {TokenData.isAuthenticated ? <Button className="loginButton" variant="link" onClick={() => handleLogout()}>Logout</Button> : ""}
        </ul>
      </nav>
    </>
  );
}

export default Navbar;