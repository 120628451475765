var CryptoJS = require("crypto-js");

const algorithm = 'aes-256-ctr';
const initVector = crypto.initVector;
const secretKey = 'ACfzz2xEfGkVSjiXm59mcSPjAulfWWpB';

export function encrypt(data) {

    const cipher = crypto.createCipheriv(algorithm, secretKey, initVector);

    let encryptedData = cipher.update(data, "utf-8", "hex");
    return encryptedData;
}

export function decrypt(encryptedData) {
    
    var bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedData);
    return decryptedData;
}

