import React, { Component } from 'react';
import Select from 'react-select';
import { Container, Form, FloatingLabel } from 'react-bootstrap';
import states from './states.json'
import TokenData from '../../utils/tokendata'

let accountName = ''

class PracticeLocations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedLocationName: { value: -1, label: '' }, optionsLocations: [], practiceLocations: [], selectedState: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.getPracticeLocations = this.getPracticeLocations.bind(this)
        this.handleStateChange = this.handleStateChange.bind(this)
        this.clearForm = this.clearForm.bind(this)
        this.onClickAddNewLocation = this.onClickAddNewLocation.bind(this)
    }

    clearForm = (txtLocationName, txtAddressLine1, txtAddressLine2, txtCity, txtZipCode, chbxActive) => {
        txtLocationName.value = ''
        txtAddressLine1.value = ''
        txtAddressLine2.value = ''
        txtCity.value = ''
        txtZipCode.value = ''
        chbxActive.checked = false
        this.setState({ selectedLocationName: { value: -1, label: '' }, selectedState: '' })
    }

    componentDidMount() {
        this.getPracticeLocations();
    }

    getPracticeLocations = async () => {
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetLocations?organizationid=${TokenData.OrganizationId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    let optionsLocations = result.map((loc) => ({ value: loc.id, label: loc.name }));
                    this.setState({ practiceLocations: result, optionsLocations: optionsLocations });
                    if(this.state.practiceLocations.length >= TokenData.MaxLocations) {
                        document.getElementById('btnAddNew').disabled=true;
                    }
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    handleStateChange = (selectedState) => {
        this.setState({ selectedState: selectedState })
    }

    handleLocationChange = (selectedLocationName) => {
        var location = this.state.practiceLocations.find((pl) => pl.id === selectedLocationName.value);
        var txtLocationName = document.getElementById('floatingInputName');
        var txtAddressLine1 = document.getElementById('floatingInputAddressLine1');
        var txtAddressLine2 = document.getElementById('floatingInputAddressLine2');
        var txtCity = document.getElementById('floatingInputCity');
        var txtZipCode = document.getElementById('floatingInputZipCode');
        var chbxActive = document.getElementById('formIsActiveCheckbox');

        if (selectedLocationName.value === -1) {
            this.clearForm(txtLocationName, txtAddressLine1, txtAddressLine2, txtCity, txtZipCode, chbxActive)
        }
        else {
            var state = states.find((st) => st.value === location.state)
            txtLocationName.value = location.name
            txtAddressLine1.value = location.addressline1
            txtAddressLine2.value = location.addressline2
            txtCity.value = location.city
            txtZipCode.value = location.zipcode
            chbxActive.checked = location.isactive

            this.setState({ selectedLocationName: selectedLocationName, selectedState: state })
        }
    }

    onClickAddNewLocation = (e) => {
        this.handleLocationChange({ value: -1, label: '' })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        var txtLocationName = document.getElementById('floatingInputName');
        var txtAddressLine1 = document.getElementById('floatingInputAddressLine1');
        var txtAddressLine2 = document.getElementById('floatingInputAddressLine2');
        var txtCity = document.getElementById('floatingInputCity');
        var txtZipCode = document.getElementById('floatingInputZipCode');
        var chbxActive = document.getElementById('formIsActiveCheckbox');
        var isActive = 0;

        var location = this.state.practiceLocations.find((pl) => pl.id === this.state.selectedLocationName.value)
        console.log('location', location)
        console.log('state locations', this.state.practiceLocations)
        console.log('selectedlocationname', this.state.selectedLocationName)

        if (chbxActive.checked === true) {
            isActive = 1;
        }

        this.setLocation(this.state.selectedLocationName, TokenData.OrganizationId, txtLocationName.value, txtAddressLine1.value, txtAddressLine2.value,
            txtCity.value, this.state.selectedState.value, txtZipCode.value, isActive,
             accountName, accountName)

        alert("Saved successfully");
        this.clearForm(txtLocationName, txtAddressLine1, txtAddressLine2, txtCity, txtZipCode, chbxActive)
        this.getPracticeLocations()

    }

    setLocation = async (selectedLocation, organizationId, locationName,
        addressLine1, addressLine2, city, state,
        zipCode, isActive, createdBy, modifiedBy) => {

        var bodyReq = JSON.stringify(
            {
                locationId: selectedLocation.value,
                organizationId: organizationId,
                locationName: locationName,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                zipCode: zipCode,
                isActive: isActive,
                createdBy: createdBy,
                modifiedBy: modifiedBy
            })
        console.log('bodyReq', bodyReq)
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/SetLocation`, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: bodyReq
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    if (result.updateCoordinates === 1) {
                        this.updateCoordinates(result.locationId)
                    }
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    updateCoordinates = async (locationId) => {
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/UpdatePracticeLocationCordinates`, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(
                {
                    locationid: locationId
                })
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    render() {
        accountName = TokenData.userFullName;
        return (
            <Container className="content p-3">
                <Container className="p-5 mb-4 bg-light rounded-3">
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group className="align-right remove-margin-40" >
                            <input type="button" onClick={this.onClickAddNewLocation} id="btnAddNew" value="Add New Location" className='btn btn-primary' />
                            <input type="submit" value="Save" className='btn btn-primary' />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Select
                                name="select-location"
                                value={this.state.selectedLocationName}
                                onChange={this.handleLocationChange}
                                options={this.state.optionsLocations}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">

                            <FloatingLabel
                                controlId="floatingInputName"
                                label="Location Name:"
                                className="mb-3">
                                <Form.Control type="text" placeholder="Enter location name" />
                            </FloatingLabel>
                            <Form.Check type="checkbox" id="formIsActiveCheckbox" label="Is Active?" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <FloatingLabel
                                controlId="floatingInputAddressLine1"
                                label="Address Line 1:"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Enter address line 1" />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInputAddressLine2"
                                label="Address Line 2:"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Enter address line 2" />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInputCity"
                                label="City:"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Enter City" />
                            </FloatingLabel>
                            <Select
                                name="select-state"
                                options={states}
                                value={this.state.selectedState}
                                onChange={this.handleStateChange}
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <FloatingLabel
                                controlId="floatingInputZipCode"
                                label="Zip Code:"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Enter Zip Code" />
                            </FloatingLabel>
                        </Form.Group>

                    </Form>
                </Container>
            </Container>
        )
    }
}

export default PracticeLocations;