import React, { Component, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import '../Login/Login.css'
import BGImg from './bg.png'
import { decrypt } from '../../utils/tituscrypto'
import bcrypt from 'bcryptjs';
import storage from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


let orgData = [];

class CreateOrg extends Component {
    constructor(props) {
        super(props);
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
        let token = decodeURIComponent(params.token); // "some_value"
        console.log(token);

        orgData = JSON.parse(decrypt(token))
        console.log();

        this.state = {
            file: "", percent: 0, fileUrl: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);

    }

    // Handle file upload event and update state
    handleChange(event) {
        this.setState({ file: event.target.files[0] });
    }

    handleUpload = () => {
        if (!this.state.file) {
            alert("Please upload an image first!");
        }
        var txtOrgid = document.getElementById('txtOrgid');
        var txtOrgName = document.getElementById('txtOrgName');
        var txtOrgPhone = document.getElementById('txtOrgPhone');
        var txtAdminEmail = document.getElementById('txtAdminEmail');
        var txtAdminPassword = document.getElementById('txtAdminPassword');

        const storageRef = ref(storage, `/org_logos/${txtOrgid.value}-${txtOrgName.value}/${this.state.file.name}`);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, this.state.file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                this.setState({ percent: percent });
                console.log(this.state.percent + "% done")
            },
            (err) => console.log(err),
            () => {
                // download url

                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);

                    this.setState({ fileUrl: url })

                    var bodyReq = JSON.stringify(
                        {
                            Organization: {
                                Id: txtOrgid.value,
                                Name: txtOrgName.value,
                                Phone: txtOrgPhone.value,
                                LogoPath: url,
                                IsActive: true
                            },
                            AdminUserName: txtAdminEmail.value,
                            AdminPassword: bcrypt.hashSync(txtAdminPassword.value)
                        });

                    fetch(`${process.env.REACT_APP_WEB_API_URL}/organization/updateorg`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: bodyReq
                    },
                    ).then(res => res.json())
                        .then(
                            (result) => {
                                console.log("Success");
                                alert("Thanks for setting the account. Please login to Titus.")
                                window.location = '/';
                            },
                            (error) => {
                                console.log('error', error)
                            }
                        )
                });
            }
        );
    };

    render() {
        return (
            <div className='content-login-bg' style={{ backgroundImage: `url(${BGImg})` }}>
                <Container className="p-5 mb-4 bg-light rounded-3 content-org">
                    <div className='price-table-container'>
                        <section>
                            <Form action={''} method="POST">
                                <div className='text-align-left'>
                                    <Form.Label className="h6">Last Step: Company Setup</Form.Label>
                                    <hr className='line' />
                                </div>
                                <div className='text-align-left'>
                                    <Form.Control type="hidden" required id='txtOrgid' placeholder="Organization Id" className="mb-3" defaultValue={orgData.id} />
                                </div>
                                <div className='text-align-left'>
                                    Organization Name: <Form.Control type="text" required id='txtOrgName' placeholder="Organization Name" className="mb-3" />
                                </div>
                                <div className='text-align-left'>
                                    Phone: <Form.Control type="text" id='txtOrgPhone' placeholder="Organization Phone" className="mb-3" defaultValue={orgData.phone} />
                                </div>
                                <div className='text-align-left'>
                                    Org Logo: <Form.Control type="file" id='txtOrgLogo' placeholder="Organization Logo" className="mb-3" onChange={this.handleChange} accept="/image/*" />
                                </div>
                                <div className='text-align-left'>
                                    Admin Email: <Form.Control type="email" required id='txtAdminEmail' placeholder="Admin user email" className="mb-3" defaultValue={orgData.email} />
                                </div>
                                <div className='text-align-left'>
                                    Password: <Form.Control type="password" required id='txtAdminPassword' placeholder="Password" className="mb-3" />
                                </div>
                                <input type='button' id="pro-plan-btn" className='btn btn-primary plan-button' value='Submit' onClick={this.handleUpload} />
                            </Form>
                        </section>
                    </div>
                </Container>
            </div>
        )
    };
}

export default CreateOrg;