import jwt_decode from "jwt-decode";
import { Navigate } from "react-router-dom";
import React  from 'react';

export function GetAuthToken({ children }) {

    var token = sessionStorage.getItem('token');
    if (token) {
        const decode = jwt_decode(sessionStorage.getItem('token'))
        return children(decode)
    }
    return <Navigate replace to="/" />
}
