import React, { Component } from 'react';
import { Container, Form, FormGroup, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import TokenData from '../../utils/tokendata'

class Reports extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: '', endDate: '', practiceLocations: [], optionsLocations: [], selectedLocations: [], allTherapists: [],
            optionsTherapists: [], selectedTherapists: [], logs: []
        }

        this.handleStartDateChange = this.handleStartDateChange.bind(this)
        this.handleEndDateChange = this.handleEndDateChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleLocationChange = this.handleLocationChange.bind(this)
        this.getPracticeLocations = this.getPracticeLocations.bind(this)
        this.getTherapists = this.getTherapists.bind(this)
    }

    componentDidMount() {
        this.getPracticeLocations();
        this.getTherapists();
    }

    handleStartDateChange(startDate) {
        this.setState({ startDate: startDate })
    }

    handleEndDateChange(endDate) {
        this.setState({ endDate: endDate })
    }

    handleLocationChange = (selectedLocations) => {
        this.setState({ selectedLocations: selectedLocations });
    }

    handleTherapistChange = (selectedTherapists) => {
        this.setState({ selectedTherapists: selectedTherapists });
    }

    getPracticeLocations = async () => {
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetLocations?organizationid=${TokenData.OrganizationId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    this.setState({ practiceLocations: result, optionsLocations: result.map((loc) => ({ value: loc.id, label: loc.name })) });
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    getTherapists = async () => {
        var url = `${process.env.REACT_APP_FUNCTIONS_URL}/GetAllTherapists?organizationid=${TokenData.OrganizationId}`
        await fetch(url, {

            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        },
        ).then(res => res.json())
            .then(
                (result) => {
                    this.setState({ allTherapists: result, optionsTherapists: result.map(t => ({ value: t.id, label: t.name })) });
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    getLogs = async () => {
        await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetLogs`, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(
                {
                    locations: this.state.selectedLocations,
                    therapists: this.state.selectedTherapists,
                    organizationId: TokenData.OrganizationId,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate
                })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ logs: result })
                },
                (error) => {
                    console.log('error', error)
                }
            )
    }

    renderLogs(log, index) {
        return (
            <tr key={index}>
                <td>{log.locationName}</td>
                <td>{log.therapistName}</td>
                <td>{new Date(log.date.replace(' ', 'T') + "Z").toLocaleString()}</td>
            </tr>
        )
    }

    handleSubmit(e) {
        e.preventDefault();
        this.getLogs();
    }

    render() {
        return (
            <Container className="content-xlarge content-min-height-720 p-3">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group className="mb-3" controlId="reportsFilters">
                        <Row>
                            <Col md>
                                <Form.Label>Start Date:</Form.Label>
                                <DatePicker
                                    selected={this.state.startDate}
                                    className='form-control'
                                    onChange={this.handleStartDateChange}
                                />
                            </Col>
                            <Col md>
                                <Form.Label>End Date:</Form.Label>
                                <DatePicker
                                    selected={this.state.endDate}
                                    className='form-control'
                                    onChange={this.handleEndDateChange}
                                />
                            </Col>
                            <Col md>
                                <Form.Label>Location:</Form.Label>
                                <Select
                                    name="select-location"
                                    value={this.state.selectedLocations}
                                    onChange={this.handleLocationChange}
                                    isMulti
                                    options={this.state.optionsLocations}
                                >
                                </Select>
                            </Col>
                            <Col md>
                                <Form.Label>Therapist:</Form.Label>
                                <Select
                                    name="select-therapist"
                                    value={this.state.selectedTherapists}
                                    onChange={this.handleTherapistChange}
                                    isMulti
                                    options={this.state.optionsTherapists}
                                >
                                </Select>
                            </Col>
                            <Col md>
                                <FormGroup>
                                    <input type="submit" value="Search" className='btn btn-primary' />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Therapist</th>
                            <th>Date and Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.logs.map(this.renderLogs)}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default Reports;