import * as geolib from 'geolib';
import React, { Component } from 'react';
import { Button, Container, Form, FormGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import './Welcome.css'
import Therapist from './therapist';
import TokenData from '../../utils/tokendata'


// Formatting on MACOS using Shift + Option + F on Windows OS Shift + Alt + F.

var options = {
  enableHighAccuracy: true,
  maximumAge: Infinity,
  timeout: 10000,
};

let practiceLocations = [];
let allTherapists = [];
//let currentDateTime = new Date();
// let currentTime = new Date().toTimeString().substr(0, 5);

class Welcome extends Component {

  constructor(props) {
    super(props);

    this.state = { name: '', practiceLocations: [], selectedLocation: '', lon: 0.0, lat: 0.0, allTherapists: [], isAppointment: false }
    this.handleChange = this.handleChange.bind(this)
    this.handleNotification = this.handleNotification.bind(this)
    this.handleDeliveryClick = this.handleDeliveryClick.bind(this)
    this.getLocationAddress = this.getLocationAddress.bind(this)
    this.getPracticeLocations = this.getPracticeLocations.bind(this)
    this.getTherapistAvailable = this.getTherapistAvailable.bind(this)
    this.callReverseAddress = this.callReverseAddress.bind(this)
    // this.geoFetchSuccess = this.geoFetchSuccess.bind(this)
    // this.geoFetchError = this.geoFetchError.bind(this)
    this.handleAppointment = this.handleAppointment.bind(this)
    this.renderTherapists = this.renderTherapists.bind(this)
    this.renderTherapistsButtonSelect = this.renderTherapistsButtonSelect.bind(this)
    this.setTherapistsFilter = this.setTherapistsFilter.bind(this);
  }

  componentDidMount() {
    this.callReverseAddress();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isAppointment) {
      setTimeout(() => {
        window.location.replace('/');
      }, 120000);
    }
  }

  getPracticeLocations = async () => {
    await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetLocations?organizationid=${TokenData.OrganizationId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
    },
    ).then(res => res.json())
      .then(
        (result) => {
          practiceLocations = result
        },
        (error) => {
          console.log('error', error)
        }
      )
  }

  getLocationAddress = async (options) => {
    //https://blog.jscrambler.com/how-to-use-react-native-geolocation-to-get-postal-address
    if ("geolocation" in navigator) {
      console.log("Geolocation Available");
    } else {
      console.log("Geolocation Not Available");
    }
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    })
  }

  // geoFetchSuccess = position => {
  //   latitude = position.coords.latitude;
  //   longitude = position.coords.longitude;
  //   this.setState({ lon: position.coords.longitude, lat: position.coords.latitude })

  //   console.log("geoFetchSuccess " + this.state.lon + " " + this.state.lat)
  // };

  // geoFetchError(err) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }

  getTherapistAvailable = async () => {
    await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/GetTherapistsAvailable?organizationid=${TokenData.OrganizationId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    },
    ).then(res => res.json())
      .then(
        (result) => {
          allTherapists = result;
        },
        (error) => {
          console.log('error', error)
        }
      )
  }

  callReverseAddress = async () => {
    await this.getPracticeLocations();

    this.setState({ practiceLocations: practiceLocations })

    await this.getTherapistAvailable();

    if (TokenData.DefaultPracticeLocationId === 'null' || TokenData.DefaultPracticeLocationId === 0) {

      this.getLocationAddress(options).then((position) => {

        this.setState({ lon: position.coords.longitude, lat: position.coords.latitude })

        console.log('lat state', this.state.lat)
        console.log('long state', this.state.lon)

        fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.state.lat}&lon=${this.state.lon}&namedetails=1`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        },
        ).then(response => {
          if (response.ok) {
            response.json().then(json => {
              
              const practiceCoords = practiceLocations.map(practice =>
                ({ latitude: practice.latitude, longitude: practice.longitude })
              );

              const currentCoords = ({ latitude: this.state.lat, longitude: this.state.lon });
              //const currentCoords = ({ latitude: 33.001153, longitude: -96.844256 });
              const nearestCoords = geolib.findNearest(currentCoords, practiceCoords);

              this.setState({
                selectedLocation: practiceLocations.find(data => data.latitude === nearestCoords.latitude && data.longitude === nearestCoords.longitude).name
              });

              console.log("wait")
              this.setTherapistsFilter();  
            });
          }
        });
      })

    }
    else {
      this.setState({
        selectedLocation: practiceLocations.find(data => data.id === parseInt(TokenData.DefaultPracticeLocationId)).name
      });
      this.setTherapistsFilter();
    }
  }

  setTherapistsFilter() {
    console.log("selected location is: " + this.state.selectedLocation)
    let currentTime = new Date().toTimeString().substr(0, 5);
    this.setState({
      allTherapists: allTherapists.filter((th) =>
        th.locationid === (this.state.practiceLocations.find((pc) => pc.name === this.state.selectedLocation).id) &&
        currentTime >= th.starttime && currentTime <= th.endtime)
    });
  }

  sendNotification = (therapistSelected, messageVerbose) => {
    var apiPath = process.env.REACT_APP_LOGICAPP_URL;

    toast.promise(
      fetch(apiPath, {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Ocp-Apim-Subscription-Key": "bd4825a9d77d49e3b658ce9b0896f4fe" },
        body: JSON.stringify(
          {
            slackMemberId: therapistSelected.slackmemberid,
            phoneNumber: therapistSelected.cellphonenumber,
            isSlack: therapistSelected.isslack,
            isSms: therapistSelected.issms,
            message: messageVerbose,
            locationId: practiceLocations.find((pc) => pc.name === this.state.selectedLocation).id,
            therapistId: therapistSelected.therapistid,
            isEmail: therapistSelected.isemail,
            email: therapistSelected.email,
            twilioAccPhoneNumber: process.env.REACT_APP_TWILIO_ACC_PHONE_NUMBER,
            slackDeliveryChannelId: TokenData.slackDeliveryChannelId,
            slackToken: TokenData.slackToken
          })
      }).then(() => {
        console.log('api call complete');
        this.sendLog(practiceLocations.find((pc) => pc.name === this.state.selectedLocation).id, therapistSelected.therapistid,
          TokenData.OrganizationId)
      }),
      {
        pending: 'Notifying...',
        success: "Thank you for checking in. Your therapist will be with you shortly.",
        error: "Failed to notify, please try again."
      });
  }

  sendLog = (locationId, therapistId, organizationId) => {
    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/InsertLog`, {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify(
        {
          locationId: locationId,
          therapistId: therapistId,
          organizationId: organizationId
        })
    },
    ).then(res => res.json())
      .then(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log('error', error)
        }
      )
  }

  sendDeliveryNotification = (messageVerbose) => {
    var apiPath = process.env.REACT_APP_LOGICAPP_URL;
    toast.promise(
      fetch(apiPath, {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Ocp-Apim-Subscription-Key": "bd4825a9d77d49e3b658ce9b0896f4fe" },
        body: JSON.stringify(
          {
            isDelivery: 1,
            message: messageVerbose,
            twilioAccPhoneNumber: process.env.REACT_APP_TWILIO_ACC_PHONE_NUMBER,
            slackDeliveryChannelId: TokenData.slackDeliveryChannelId,
            slackToken: TokenData.slackToken
          })
      }).then(() => {
        console.log('api call complete');
      }),
      {
        pending: 'Notifying...',
        success: "Delivery notification has been sent. Please leave the package by the door and someone will collect it soon. Have a nice day.",
        error: "Failed to notify, please try again."
      });
  }

  handleChange(event) {
    let test = (this.state.allTherapists.filter((th) =>
      th.locationid === (this.state.practiceLocations.find((pc) => pc.name === event.target.value).id)).map(data => data.name));

    if (test === []) {
      test = null;
    }
    let currentTime = new Date().toTimeString().substr(0, 5);
    this.setState({
      selectedLocation: event.target.value,
      therapists: test,
      allTherapists: allTherapists.filter((th) =>
        th.locationid === (this.state.practiceLocations.find((pc) => pc.name === event.target.value).id) &&
        currentTime >= th.starttime && currentTime <= th.endtime)
    });
  }

  handleNotification(therapist) {
    let messageVerbose = `Your guest has checked in at ${new Date()} and is in the ${this.state.selectedLocation} location waiting area.`;
    this.sendNotification(therapist, messageVerbose)
    this.setState({ isAppointment: false })
  }

  handleDeliveryClick = (e) => {
    e.preventDefault();
    let messageVerbose = `There is a delivery at ${this.state.selectedLocation} location.`;
    this.sendDeliveryNotification(messageVerbose)
  }

  handleAppointment = (e) => {
    this.setState({ isAppointment: true })
  }

  renderTherapists() {
    var therapistsAvailable = this.state.allTherapists;
    return (
      therapistsAvailable.map(this.renderTherapistsButtonSelect)
    )
  }

  renderTherapistsButtonSelect(therapist, index) {
    return (
      <Therapist key={index} name={therapist.name} index={index} onClick={e => {
        e.persist();
        this.handleNotification(therapist);
      }} />
    )
  }

  render() {
    return (
      <div>
        {this.state.isAppointment ?
          <Container className="content p-3 mb-4 bg-light rounded-3">
            <Form.Group className="mb-3 align-center">
              <Form.Label htmlFor='selectTherapist' className='font-15pt-center'>Select your therapist below to let them know you are here.</Form.Label>
              <div key='123' className='align-center'>
                {this.renderTherapists()}
              </div>
            </Form.Group>
          </Container>
          :
          <Container className="content-large p-4 mb-4 bg-light rounded-3">
            <FormGroup>
              <div className='align-center'>
                <Form.Label className='font-15pt-center'>Welcome to {this.state.selectedLocation}.<br />
                  Lets get you checked in.
                </Form.Label>
                <Form.Select className='location-select' onChange={this.handleChange} value={this.state.selectedLocation} id='selectLocation' hidden>
                  {this.state.practiceLocations.map((loc) => <option id={loc.id} key={loc.id} value={loc.name}>{loc.name}</option>)}
                </Form.Select>
                <br />
                <Button className='btn btn-primary btn-tablet-large' onClick={this.handleAppointment}><span className="bi bi-calendar-check large-font-icon"></span><br /><span className='large-font-text'>Appointment</span></Button>
                <Button className='btn btn-primary btn-tablet-large' onClick={this.handleDeliveryClick}><span className="bi bi-mailbox2 large-font-icon"></span><span className='large-font-text'>Delivery</span></Button>
                <Link to="/help">
                  <Button className='btn btn-primary btn-tablet-large'><span className="bi bi-question-circle large-font-icon"></span><span className='large-font-text'>Help</span></Button>
                </Link>
              </div>
            </FormGroup>
          </Container>
        }

      </div>
    )
  };
}

export default Welcome