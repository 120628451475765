import React, { Component } from 'react';
import { Container, Form, FloatingLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './Login.css'
import BGImg from '../unAuth/bg.png'
import bcrypt from 'bcryptjs';

class Login extends Component {

    handleLogin = () => {
        sessionStorage.removeItem('token');
        var txtUsername = document.getElementById('txtEmail').value;
        var txtPassword = document.getElementById('txtword').value;

        var promise = fetch(`${process.env.REACT_APP_WEB_API_URL}/user/authenticate`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": txtUsername,
                "password": ""
            })
        }).then(res => res.json())
        .then(
            (result) => {
                if (bcrypt.compareSync(txtPassword, result.result.passwordHash)) {
                    sessionStorage.setItem('token', result.result.token)
                    sessionStorage.setItem('orgToken', result.result.orgToken)
                    sessionStorage.setItem('organizationInfo', JSON.stringify(result.result.organizationInfo))
                    sessionStorage.setItem('DefaultPracticeLocationId', result.result.defaultPracticeLocationId)
                    txtPassword = ''
                    txtUsername = ''
                    window.location = '/';
                }
                else {
                    txtPassword = ''
                    console.log("invalid password")
                    throw new Error("invalid")
                }
            },
            (error) => {
                console.log('error', error)
            }
        )
        toast.promise(promise,
            {
                pending: 'Loading...',
                success: "Logged in!",
                error: "Username or password is incorrect"
            }
        )
    }

    render() {
        return (
            <div className='content-login-bg' style={{ backgroundImage: `url(${BGImg})` }}>
                <Container className="p-5 mb-4 bg-light rounded-3 content-login">
                    <Form>
                        <Form.Group className="mb-3">
                            <FloatingLabel
                                id="floatingInputUserEmail"
                                label="Email"
                                className="">
                                <Form.Control type="text" id='txtEmail' placeholder="Enter Email" />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <FloatingLabel
                                id="floatingInputUserWord"
                                label="Password"
                                className="">
                                <Form.Control type="password" id="txtword" placeholder="Enter Password" />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="align-right">
                            <input type="button" value="Login" className='btn btn-primary login-button' onClick={this.handleLogin} />
                        </Form.Group>
                        <Form.Group className="">
                            <div className='signup-label'>
                                DON'T HAVE AN ACCOUNT? <a href='/plans'>SIGN UP TO START NOW</a>
                            </div>
                        </Form.Group>
                    </Form>
                </Container>
            </div>
        )
    };
}

export default Login;