import React from 'react';
import { Button } from 'react-bootstrap';


const Therapist = (props) => {

    return (
    
        <Button className='btn btn-primary btn-tablet-large' onClick={props.onClick}>
            <span className="bi bi-person-square person-face"></span><br />
            <span className='large-font-text-therapist'>{props.name}</span>
        </Button>

    )}

export default Therapist;