// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDOb6gMS9Q__6uYB_QOxodllfpprmUdcGs",
  authDomain: "titus-check-in.firebaseapp.com",
  projectId: "titus-check-in",
  storageBucket: "titus-check-in.appspot.com",
  messagingSenderId: "495930427858",
  appId: "1:495930427858:web:b90f73e82ef60c4eb3a00b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default storage;
